<script setup lang="ts"></script>

<template>
    <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_462_1854)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19 38C8.52336 38 0 29.4766 0 19C0 8.52336 8.52336 0 19 0C29.4766 0 38 8.52336 38 19C38 29.4766 29.4766 38 19 38ZM27.51 20.9047C26.98 20.3745 26.1207 20.3748 25.5907 20.9047C25.0606 21.4347 25.0606 22.294 25.5907 22.824L26.3264 23.5596H23.4564C22.588 23.5596 21.8159 23.1908 21.0965 22.4319C20.5808 21.888 19.7219 21.8651 19.1778 22.3807C18.6339 22.8965 18.6109 23.7554 19.1267 24.2994C20.3689 25.6097 21.8257 26.274 23.4563 26.274H26.3262L25.5906 27.0096C25.0605 27.5396 25.0605 28.3989 25.5906 28.9288C25.8555 29.1939 26.2029 29.3264 26.5501 29.3264C26.8974 29.3264 27.2447 29.1939 27.5097 28.9288L30.5621 25.8764C31.0922 25.3464 31.0922 24.4871 30.5621 23.9571L27.51 20.9047ZM27.51 9.07116C26.98 8.54102 26.1207 8.54117 25.5907 9.07116C25.0606 9.60123 25.0606 10.4605 25.5907 10.9905L26.3264 11.7261H23.4564C21.9718 11.7261 19.8243 12.3156 18.0253 15.124L13.997 21.412C13.0712 22.8571 12.0423 23.5596 10.8514 23.5596H8.39733C7.64787 23.5596 7.04017 24.1673 7.04017 24.9167C7.04017 25.6662 7.6478 26.2739 8.39733 26.2739H10.8514C12.336 26.2739 14.4834 25.6844 16.2825 22.876L20.3108 16.588C21.2366 15.1429 22.2655 14.4404 23.4563 14.4404H26.3263L25.5906 15.176C25.0606 15.7059 25.0606 16.5653 25.5906 17.0952C25.8556 17.3603 26.2029 17.4928 26.5502 17.4928C26.8975 17.4928 27.2448 17.3603 27.5098 17.0952L30.5622 14.0429C31.0922 13.5129 31.0922 12.6536 30.5622 12.1236L27.51 9.07116ZM8.39733 14.4404H10.8513C11.7198 14.4404 12.4918 14.8094 13.2112 15.5681C13.478 15.8496 13.8368 15.9915 14.1963 15.9915C14.5314 15.9915 14.8673 15.8681 15.1297 15.6193C15.6737 15.1037 15.6967 14.2447 15.1809 13.7007C13.9388 12.3904 12.4822 11.7261 10.8513 11.7261H8.39726C7.6478 11.7261 7.04009 12.3338 7.04009 13.0833C7.04017 13.8328 7.64787 14.4404 8.39733 14.4404Z"
                fill="url(#paint0_linear_462_1854)"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_462_1854"
                x1="19"
                y1="0"
                x2="19"
                y2="38"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#A2BADC" />
                <stop offset="1" stop-color="#68799C" />
            </linearGradient>
            <clipPath id="clip0_462_1854">
                <rect width="38" height="38" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
